import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ROLES, User } from '../../types/users';
import { selectCurrentUser } from '../../store/user/selectors';
import {
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useReactivateUserMutation,
  useUsersQuery
} from '../../store/services/usersApi';
import { handleCatch } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import Select from '../../components/Select';
import ConfirmModal from '../../components/ConfirmModal';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import Button from '../../components/Button';

import { UserStatusType, userStatusTypeOptions } from './userStatusOptions';
import { usersColumns } from './Users.table';

import './Users.scss';

const PAGE_SIZE = 10;

const Users = () => {
  const currentUser = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const usersRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useTitle('Users');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [userStatus, setUserStatus] = useState<UserStatusType>('');

  const handleSortChange = (sort: Sort<User>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
    setCurrentPage(0);
  };

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : undefined;

  const {
    data: usersList,
    isFetching,
    refetch
  } = useUsersQuery({
    page_size: PAGE_SIZE,
    page: currentPage,
    sort: sortingQueryParam,
    ...(userStatus !== '' && { q: `active:${userStatus}` })
  });

  const [deleteUser, { isLoading: isLoadingDeleteUser, error: errorDelete }] =
    useDeleteUserMutation();

  const [reactivateUser, { isLoading: isLoadingReactivateUser, error: errorReactivate }] =
    useReactivateUserMutation();

  const [deactivateUser, { isLoading: isLoadingDeactivateUser, error: errorDeactivate }] =
    useDeactivateUserMutation();

  const isLoading = useMemo(
    () =>
      isFetching ||
      isLoadingDeleteUser ||
      loading ||
      isLoadingReactivateUser ||
      isLoadingDeactivateUser,
    [isFetching, isLoadingDeleteUser, loading, isLoadingReactivateUser, isLoadingDeactivateUser]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!usersRef.current || !buttonRef.current) return;

      const { bottom } = usersRef.current.getBoundingClientRect();
      const buttonHeight = buttonRef.current.offsetHeight;

      const offsetBottom = 20;

      if (bottom <= window.innerHeight - offsetBottom - buttonHeight) {
        buttonRef.current.style.bottom = '3rem';
        buttonRef.current.style.position = 'absolute';
      } else {
        buttonRef.current.style.position = 'fixed';
        buttonRef.current.style.bottom = '2rem';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedId(undefined);
  };

  const handleCloseActivateModal = () => {
    setIsActivateModalOpen(false);
    setSelectedId(undefined);
  };

  const handleOpenDeleteModal = (id: string) => {
    setIsDeleteModalOpen(true);
    setSelectedId(id);
  };

  const handleOpenDeactivateModal = (id: string) => {
    setIsDeactivateModalOpen(true);
    setSelectedId(id);
  };

  const handleCloseDectivateModal = () => {
    setIsDeactivateModalOpen(false);
    setSelectedId(undefined);
  };

  const handleOpenActivateModal = (id: string) => {
    setIsActivateModalOpen(true);
    setSelectedId(id);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleUserStatusChange = (value: UserStatusType) => {
    setUserStatus(value);
    setCurrentPage(0);
  };

  const onDelete = async () => {
    if (selectedId) {
      try {
        setLoading(true);
        await deleteUser({ id: selectedId }).unwrap();

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
        setIsDeleteModalOpen(false);
      } catch (e) {
        handleCatch(e);
        setIsDeleteModalOpen(false);
      }
    }
  };

  const onReactivate = async () => {
    if (selectedId) {
      try {
        setLoading(true);
        await reactivateUser({ id: selectedId }).unwrap();

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
        setIsActivateModalOpen(false);
      } catch (e) {
        handleCatch(e);
        setIsActivateModalOpen(false);
      }
    }
  };

  const onDeactivate = async () => {
    if (selectedId) {
      try {
        setLoading(true);
        await deactivateUser({ id: selectedId }).unwrap();

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
        setIsDeleteModalOpen(false);
      } catch (e) {
        handleCatch(e);
        setIsDeleteModalOpen(false);
      }
    }
  };
  const renderError = useMemo(
    () =>
      errorDelete || errorReactivate || errorDeactivate ? (
        <div className="users-error-message">Something went wrong</div>
      ) : null,
    [errorDelete, errorReactivate, errorDeactivate]
  );

  return (
    <div className="users" ref={usersRef}>
      <h1 className="users-title">Organization Users</h1>
      <div className="users-cta-container">
        <Select
          options={userStatusTypeOptions}
          value={userStatus}
          onChange={(value) => handleUserStatusChange(value as UserStatusType)}
          id="device-type"
          className="users-select"
          placeholder="Filter by"
          label="Filter by"
        />
      </div>

      <CNCTable<User>
        className="users-table"
        columns={usersColumns({
          onDeactivate: handleOpenDeactivateModal,
          onDelete: handleOpenDeleteModal,
          onActivate: handleOpenActivateModal,
          currentUserId: currentUser?.id,
          canDelete: currentUser?.role && [ROLES.VAR, ROLES.LOCAL_ADMIN].includes(currentUser.role)
        })}
        data={usersList}
        loading={isLoading}
        onPageChange={handlePageChange}
        onSort={handleSortChange}
      />

      {renderError}

      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={onDelete}
        message="Are you sure you want to permanently delete this user? This action cannot be undone."
      />

      <ConfirmModal
        isOpen={isActivateModalOpen}
        onClose={handleCloseActivateModal}
        onConfirm={onReactivate}
        variant="primary"
        message="Are you sure you want to reactivate this user?"
      />

      <ConfirmModal
        isOpen={isDeactivateModalOpen}
        onClose={handleCloseDectivateModal}
        onConfirm={onDeactivate}
        message="Are you sure you want to deactivate this user?"
      />

      <Button
        className="users-create-button"
        ref={buttonRef}
        onClick={() => navigate(ROUTES.USER_CREATE)}
      >
        Create User
      </Button>
    </div>
  );
};

export default Users;
