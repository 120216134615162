// Regular expressions
const nameRegex = /^[A-Za-z ]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^\d{10}$/;
const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Validation logic
export const validateDomain = (domain: string) => {
  if (!domain) return 'Please enter a domain address.';

  if (!domainRegex.test(domain)) return 'Invalid domain format.';

  return '';
};

export const validatePassword = (password: string) => {
  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  if (password.length > 20) {
    return 'Password must not exceed 20 characters';
  }

  return '';
};

export const validateName = (name: string) => {
  if (!name) return 'Please enter a name.';

  if (!nameRegex.test(name)) return 'Invalid name. Only letters and spaces are allowed.';

  return '';
};

export const validateEmail = (email: string) => {
  if (!email) return 'Please enter an email address.';

  if (!emailRegex.test(email)) return 'Invalid email format.';

  return '';
};

export const validateCompany = (company: string) => {
  if (!company) return 'Please enter a company name.';

  return '';
};

export const validateNumber = (number: string) => {
  if (!number) return 'Please enter a phone number.';

  if (!phoneRegex.test(number)) {
    return 'Please enter your 10-digit phone number in this format: 5551231234. Be sure not to include spaces.';
  }

  return '';
};
