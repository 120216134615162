import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';

import { ROLES } from '../../types/users';
import { Event, EventState } from '../../types/events';
import { selectCurrentUser } from '../../store/user/selectors';
import { handleCatch } from '../../store/services/helpers';
import {
  useEventsQuery,
  useStopEventMutation,
  useDeleteEventMutation
} from '../../store/services/eventsApi';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import Select from '../../components/Select';
import ConfirmModal from '../../components/ConfirmModal';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import Button from '../../components/Button';

import { eventsColumns } from './Events.table';

import './Events.scss';

type StateFilter = { value: EventState | string; label: string };

const PAGE_SIZE = 10;

const FILTER_OPTIONS: StateFilter[] = [
  { value: '', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'finished', label: 'Finished' },
  { value: 'scheduled', label: 'Scheduled' }
];

const Events = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  useTitle('Events');

  const [filter, setFilter] = useState(FILTER_OPTIONS[0].value);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : 'start_time:DESC';

  const {
    data,
    isLoading: isLoadingEvents,
    refetch
  } = useEventsQuery({
    ...(filter && !!filter.length && { q: `state:${filter}` }),
    page_size: PAGE_SIZE,
    page: currentPage,
    sort: sortingQueryParam
  });

  const [stopEvent, { isLoading: isLoadingStop }] = useStopEventMutation();
  const [deleteEvent, { isLoading: isLoadingDelete }] = useDeleteEventMutation();

  const isLoading = useMemo(
    () => isLoadingEvents || isLoadingStop || isLoadingDelete || loading,
    [isLoadingEvents, isLoadingStop, isLoadingDelete, loading]
  );

  const handleFilterChange = (value: string) => {
    setFilter(value as EventState);
    setCurrentPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId(undefined);
  };

  const handleOpenModal = (id: string) => {
    setIsModalOpen(true);
    setSelectedId(id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteEventModalOpen(false);
    setSelectedId(undefined);
  };

  const handleOpenDeleteModal = (id: string) => {
    setIsDeleteEventModalOpen(true);
    setSelectedId(id);
  };

  const onStopEvent = async () => {
    if (selectedId) {
      try {
        setLoading(true);
        await stopEvent({ id: selectedId }).unwrap;

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
        setIsModalOpen(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const onDeleteEvent = async () => {
    if (selectedId) {
      try {
        setIsDeleteEventModalOpen(false);
        setLoading(true);
        await deleteEvent({ id: selectedId }).unwrap();

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
      } catch (e) {
        setLoading(false);
        handleCatch(e);
      }
    }
  };

  const handleSortChange = (sort: Sort<Event>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
    setCurrentPage(0);
  };

  return (
    <div className="events">
      <div className="header-container">
        <div className="single-event-container">
          <h1 className="title">Event</h1>

          <h5 className="description">
            {`Create an event for ${
              user?.role === ROLES.VAR ? 'one or more organizations.' : 'your organization.'
            } Specific streaming devices can be shared and outside agencies/people can be invited to
            participate.`}
          </h5>

          <Button onClick={() => navigate(ROUTES.EVENT_CREATE)} className="create-button">
            Create Event
          </Button>
        </div>
      </div>

      <h1 className="title">Organizations events</h1>

      <h5 className="description">
        Create events for your organization, limited to 3 concurrent events and a 7 day maximum
        duration.
      </h5>

      <div className="events-table-container">
        <div className="filter-container">
          <Select
            label="Filter by"
            id="state-filter"
            className="events-table-filter"
            value={filter}
            options={FILTER_OPTIONS}
            onChange={(value) => handleFilterChange(value as string)}
          />
        </div>

        <CNCTable<Event>
          columns={eventsColumns({
            onStopEvent: handleOpenModal,
            onDeleteEvent: handleOpenDeleteModal,
            isVar: user?.role === ROLES.VAR
          })}
          data={data}
          loading={isLoading}
          className="events-table"
          pageSize={PAGE_SIZE}
          pageNumber={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSortChange}
          scrollX={1000}
        />
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={onStopEvent}
        message="Are you sure you want to stop this event?"
      />
      <ConfirmModal
        isOpen={isDeleteEventModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={onDeleteEvent}
        message="Are you sure you want to delete this event?"
      />
    </div>
  );
};

export default Events;
