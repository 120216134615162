import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';

import { setPasswordCreated } from '../../../store/user/slice';
import { selectIsLoggedIn } from '../../../store/user/selectors';
import {
  useConfirmForgotPasswordMutation,
  useCreatePasswordMutation
} from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';
import { validatePassword } from '../../../helpers/validators';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import debounceValidation from '../../../helpers/debounceValidation';
import Loader from '../../../components/Loader';
import InputField from '../../../components/Input';
import Criteria from '../../../components/Criteria';
import Button from '../../../components/Button';
import logo from '../../../assets/logo.png';

import './CreatePasswordForm.scss';

const CreatePasswordForm: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [passwordError, setPasswordError] = useState('');

  // Info from router's state
  const session = location.state?.session;
  const email = location.state?.email;
  const code = location.state?.code;
  const from = location.state?.from?.pathname || ROUTES.DASHBOARD;

  // This is used when a new user needs to create a new password
  const [createPassword, { isLoading: isLoadingCreate, error: errorCreate }] =
    useCreatePasswordMutation();
  // This is used when a user forgot their password and has to reset it
  const [confirmForgotPassword, { isLoading: isLoadingConfirm, error: errorConfirm }] =
    useConfirmForgotPasswordMutation();

  const isLoading = useMemo(
    () => isLoadingCreate || isLoadingConfirm,
    [isLoadingCreate, isLoadingConfirm]
  );
  const reqError = useMemo(() => errorCreate || errorConfirm, [errorCreate, errorConfirm]);

  // States
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [challengeCode, setChallengeCode] = useState('');
  const [criteria, setCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false
  });

  useEffect(() => {
    if (isFetchBaseQueryError(reqError)) {
      setError(reqError?.data?.message);
    }
  }, [reqError]);

  useEffect(() => {
    setCriteria({
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /[@$!%*?&]/.test(password),
      number: /\d/.test(password)
    });
  }, [password]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(from, { replace: true });
    }
  }, [isLoggedIn, navigate, from]);

  const areAllCriteriaMet = useMemo(
    () => Object.values(criteria).every((isMet) => isMet),
    [criteria]
  );

  const handleSubmit = async () => {
    try {
      if (code) {
        await confirmForgotPassword({
          username: email,
          password,
          code: challengeCode
        }).unwrap();
      } else {
        await createPassword({
          username: email,
          newPassword: password,
          session
        }).unwrap();
      }

      dispatch(setPasswordCreated(true));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    debounceValidation(() => setPasswordError(validatePassword(newPassword)));

    if (newPassword !== confirmPassword && confirmPassword.length) {
      setError('Passwords must match');
    } else {
      setError('');
    }
  };

  const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (value !== password) {
      setError('Passwords must match');
    } else {
      setError('');
    }
  };

  if (isLoggedIn) return null;

  return (
    <section className="create-form">
      <img src={logo} alt="logo" className="create-form-logo" />
      {isLoading && <Loader />}
      <header>
        <h2>Create new Password</h2>
      </header>

      <InputField
        type="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
        id="password"
        label="New Password *"
        isPassword
        error={passwordError}
        showErrorBlock
        success={areAllCriteriaMet}
      />

      <ul className="create-form-requirements">
        <Criteria white isCriteriaMet={criteria.minLength} message="Minimum 8 characters" />
        <Criteria
          white
          isCriteriaMet={criteria.uppercase}
          message="Includes an uppercase character"
        />
        <Criteria
          white
          isCriteriaMet={criteria.lowercase}
          message="Includes a lowercase character"
        />
        <Criteria
          white
          isCriteriaMet={criteria.specialChar}
          message="Includes a special character"
        />
        <Criteria white isCriteriaMet={criteria.number} message="Includes a number" />
      </ul>

      <InputField
        type="password"
        value={confirmPassword}
        onChange={handleConfirmPassword}
        onKeyDown={handleKeyDown}
        id="confirm-password"
        label="Confirm Password *"
        isPassword
        error={!code ? error : ''}
        showErrorBlock
        success={password === confirmPassword && !!confirmPassword.length}
      />

      {code && (
        <>
          <InputField
            type="text"
            value={challengeCode}
            onChange={(e) => {
              setChallengeCode(e.target.value);
            }}
            id="code"
            label="Email Code *"
            error={error}
            showErrorBlock
          />
          <p style={{ fontSize: '1.2rem', color: 'white' }}>
            Please enter the 6-character code sent to your email.
          </p>
        </>
      )}

      <div className="create-form-actions">
        <Button
          disabled={
            isLoading ||
            !!error ||
            !password.length ||
            !confirmPassword.length ||
            !areAllCriteriaMet
          }
          onClick={handleSubmit}
          className="create-form-submit-button"
        >
          Confirm
        </Button>
        <Button onClick={() => navigate(ROUTES.LOGIN)} variant="link">
          Back
        </Button>
      </div>
    </section>
  );
};

export default CreatePasswordForm;
