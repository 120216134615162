import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';

import { ROLES } from '../../types/users';
import { StreamingDevice } from '../../types/streamingDevices';
import { Organization } from '../../types/organizations';
import { Event } from '../../types/events';
import { ApiListResponse } from '../../types/api';
import { selectCurrentUser } from '../../store/user/selectors';
import { useLazyGetStreamingDevicesBulkQuery } from '../../store/services/streamingDevicesApi';
import { useOrganizationsQuery } from '../../store/services/organizationsApi';
import {
  generateRandomHash,
  handleCatch,
  isNullOrUndefinedOrEmpty
} from '../../store/services/helpers';
import {
  useCreateEventMutation,
  useEditEventMutation,
  useGetEventByIdQuery,
  useStopEventMutation,
  useDeleteEventMutation
} from '../../store/services/eventsApi';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import isFetchBaseQueryError from '../../helpers/isFetchBaseQueryError';
import { EVENT_OVERLAPPING_ERROR_MESSAGE } from '../../constants';
import config from '../../config';
import Select from '../../components/Select/Select';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import EmailPillInput from '../../components/EmailPillInput';
import DomainPillInput from '../../components/DomainPillInput';
import DateTimePicker from '../../components/DateTimePicker';
import ConfirmModal from '../../components/ConfirmModal';
import Checkbox from '../../components/Checkbox/Checkbox';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import Button from '../../components/Button';
import { ReactComponent as RefreshIcon } from '../../assets/refresh.svg';

import { devicesColumns } from './Devices.table';

import './EventForm.scss';
import 'react-datepicker/dist/react-datepicker.css';

const timeOptions = [
  { value: 1, label: '1 hour' },
  { value: 6, label: '6 hours' },
  { value: 12, label: '12 hours' },
  { value: 24, label: '24 hours' },
  { value: 72, label: '3 days' },
  { value: 168, label: '7 days' }
];

const EventForm = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { id } = useParams();
  const navigate = useNavigate();
  useTitle('Event');

  // States
  const [isErrorEventModalOpen, setIsErrorEventModalOpen] = useState(false);
  const [isStopEventModalOpen, setIsStopEventModalOpen] = useState(false);
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [domains, setDomains] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [duration, setDuration] = useState<number | undefined>();
  const [localDevices, setLocalDevices] = useState<ApiListResponse<StreamingDevice>>();
  const [futureEvent, setFutureEvent] = useState(false);
  const [currentOrganizations, setCurrentOrganizations] = useState<Organization[] | undefined>();
  const [initialSelectedOrgIds, setInitialSelectedOrgIds] = useState<Array<string>>([]);
  const [streamIds, setStreamIds] = useState<Array<string>>();
  const [eventKeyUrl, setEventKeyUrl] = useState<string>();
  const [sortField, setSortField] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>();
  const [loading, setLoading] = useState(false);
  const [tableKey, setTableKey] = useState(Date.now());

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : undefined;

  // Mutations
  const [editEvent, { isLoading: isLoadingEdit, error: errorUpdateEvent }] = useEditEventMutation();
  const [createEvent, { isLoading: isLoadingCreate, error: errorCreateEvent }] =
    useCreateEventMutation();
  const [stopEvent, { isLoading: isLoadingStop }] = useStopEventMutation();
  const [deleteEvent, { isLoading: isLoadingDelete }] = useDeleteEventMutation();

  // Queries
  const { data: organizationsData, isLoading: isLoadingOrganizations } = useOrganizationsQuery(
    { q: 'active:true', page_size: 1000 },
    { skip: currentUser?.role !== ROLES.VAR }
  );
  const [getStreamingDevicesBulk, { isLoading: isLoadingBulk }] =
    useLazyGetStreamingDevicesBulkQuery();

  const { data: eventData, isLoading: isLoadingEvent } = useGetEventByIdQuery(
    { id: id || '' },
    {
      skip: !id
    }
  );

  // Memos
  const isLoading = useMemo(
    () =>
      isLoadingOrganizations ||
      isLoadingEvent ||
      isLoadingEdit ||
      isLoadingCreate ||
      isLoadingStop ||
      isLoadingDelete ||
      loading,
    [
      isLoadingOrganizations,
      isLoadingEvent,
      isLoadingEdit,
      isLoadingCreate,
      isLoadingStop,
      isLoadingDelete,
      loading
    ]
  );

  const organizationsOptions = useMemo(
    () =>
      currentUser
        ? currentUser.role === ROLES.VAR
          ? organizationsData?.items?.map((org) => ({ label: org.name, value: org.id }))
          : [{ label: currentUser.organization.name, value: currentUser.organization.id }]
        : [],
    [organizationsData, currentUser]
  );

  const selectedOrganizationIds = useMemo(
    () =>
      currentUser
        ? currentUser.role === ROLES.VAR
          ? currentOrganizations?.map((org) => org.id) || []
          : [currentUser.organization.id]
        : [],
    [currentOrganizations, currentUser]
  );

  const isDataModified = useMemo(() => {
    const nameChanged = name !== eventData?.name;
    const durationChanged = duration !== eventData?.duration;
    const emailsChanged = !isEqual(emails?.slice().sort(), eventData?.invitees?.slice().sort());
    const domainsChanged = !isEqual(
      domains?.slice().sort(),
      eventData?.authorized_domains?.slice().sort()
    );
    const streamIdsChanged =
      !isEqual(streamIds?.slice().sort(), eventData?.stream_ids?.slice().sort()) &&
      streamIds?.length;
    const organizationIdsChanged = !isEqual(
      selectedOrganizationIds?.slice().sort(),
      eventData?.organization_ids?.slice().sort()
    );
    const startTimeChanged =
      (!isNullOrUndefinedOrEmpty(startTime) || !isNullOrUndefinedOrEmpty(eventData?.start_time)) &&
      startTime !== eventData?.start_time;
    const eventKeyUrlChanged = eventKeyUrl !== eventData?.event_url_key;

    return (
      eventData &&
      (nameChanged ||
        durationChanged ||
        emailsChanged ||
        domainsChanged ||
        streamIdsChanged ||
        organizationIdsChanged ||
        startTimeChanged ||
        eventKeyUrlChanged)
    );
  }, [
    name,
    duration,
    emails,
    domains,
    selectedOrganizationIds,
    eventData,
    startTime,
    eventKeyUrl,
    streamIds
  ]);

  const isEventFormValid = useMemo(
    () =>
      name &&
      duration !== undefined &&
      !!domains?.length &&
      !!selectedOrganizationIds?.length &&
      !!streamIds?.length,
    [name, duration, domains, selectedOrganizationIds, streamIds]
  );

  const shouldEnableSaveChanges = useMemo(
    () => (id && isDataModified && isEventFormValid) || (!id && isEventFormValid),
    [id, isDataModified, isEventFormValid]
  );

  const shouldEnableCancel = useMemo(
    () =>
      (id && isDataModified) ||
      (!id &&
        (name ||
          duration !== undefined ||
          !!emails.length ||
          !!domains.length ||
          (currentUser?.role === ROLES.VAR && !!selectedOrganizationIds.length) ||
          startTime)),
    [id, isDataModified, name, duration, emails, domains, selectedOrganizationIds, startTime]
  );

  const populateForm = async (force = false) => {
    if (eventData) {
      let currentOrgIds: string[] = [];
      setName(eventData.name);
      setDuration(eventData.duration);
      setFutureEvent(!!eventData.start_time && eventData.state === 'scheduled');
      setStartTime(eventData.start_time);
      setDomains(eventData.authorized_domains);
      setEmails(eventData.invitees);
      setStreamIds(eventData.stream_ids);
      setEventKeyUrl(eventData.event_url_key);

      if ((currentUser?.role === ROLES.VAR && !currentOrganizations?.length) || force) {
        setCurrentOrganizations(
          organizationsData?.items.filter((org) => eventData.organization_ids?.includes(org.id))
        );
        currentOrgIds = eventData.organization_ids;
      } else if (
        (currentUser?.role === ROLES.LOCAL_ADMIN && !currentOrganizations?.length) ||
        (force && currentUser)
      ) {
        setCurrentOrganizations([currentUser.organization as Organization]);
        currentOrgIds = [currentUser.organization.id];
      }

      if (currentOrgIds?.length) {
        try {
          const devicesRes = await getStreamingDevicesBulk({
            organizationIds: currentOrgIds,
            sort: sortingQueryParam
          }).unwrap();
          setLocalDevices(devicesRes);
        } catch (e) {
          handleCatch(e);
        }
      }
    }
  };

  const fetchDevicesIfNeeded = async () => {
    const hasNewSelections =
      selectedOrganizationIds.some((id) => !initialSelectedOrgIds.includes(id)) ||
      initialSelectedOrgIds.some((id) => !selectedOrganizationIds.includes(id));

    if (hasNewSelections && selectedOrganizationIds.length) {
      try {
        const res = await getStreamingDevicesBulk({
          organizationIds: selectedOrganizationIds,
          sort: sortingQueryParam
        }).unwrap();
        const mappedRes = {
          ...res,
          items: res.items.map((device) => ({ ...device, added: false }))
        };

        setLocalDevices(mappedRes);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const generateEventKey = () =>
    setEventKeyUrl(
      `${config.baseUrl}${ROUTES.EVENT_LOGIN.replace(':eventKey', generateRandomHash())}`
    );

  // Effects
  useEffect(() => {
    if (
      errorCreateEvent &&
      isFetchBaseQueryError(errorCreateEvent) &&
      errorCreateEvent?.status === 409
    ) {
      setIsErrorEventModalOpen(true);
    }
  }, [errorCreateEvent]);

  useEffect(() => {
    if (
      errorUpdateEvent &&
      isFetchBaseQueryError(errorUpdateEvent) &&
      errorUpdateEvent?.status === 409
    ) {
      setIsErrorEventModalOpen(true);
    }
  }, [errorUpdateEvent]);

  useEffect(() => {
    if (!futureEvent) {
      setStartTime(null);
    }
  }, [futureEvent]);

  useEffect(() => {
    if (id) {
      populateForm();
    }
  }, [eventData, id, organizationsData, isLoadingBulk]);

  useEffect(() => {
    fetchDevicesIfNeeded();
  }, [sortingQueryParam]);

  useEffect(() => {
    if (!id && !eventData) {
      generateEventKey();
    }
  }, [id, eventData]);

  // Handlers
  const resetTable = () => {
    setTableKey(Date.now());
  };

  const resetForm = () => {
    setName('');
    setDuration(undefined);
    setFutureEvent(false);
    setStartTime(null);
    setDomains([]);

    if (currentUser?.role === ROLES.LOCAL_ADMIN && !currentOrganizations?.length) {
      setCurrentOrganizations([currentUser.organization as Organization]);
    } else {
      setCurrentOrganizations(undefined);
    }

    setEmails([]);
    setStreamIds([]);
    setLocalDevices(undefined);
    generateEventKey();
    resetTable();
  };

  const onSelectDevice = async (selectedDevices: StreamingDevice[]) => {
    const newStreamIds = selectedDevices.map((device) => device.id);
    setStreamIds(newStreamIds);
  };

  const handleSelectDurationChange = (value: number) => {
    setDuration(value);
  };

  const handleSelectOpen = () => {
    setInitialSelectedOrgIds([...selectedOrganizationIds]);
  };

  const handleSelectOrganizations = (selectedIds: Array<string>) => {
    const selectedOrgs = organizationsData?.items.filter((org) => selectedIds.includes(org.id));
    setCurrentOrganizations(selectedOrgs);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleSubmit = async () => {
    try {
      if (id) {
        const updatedFields: Partial<Event> = {};

        if (name !== eventData?.name) updatedFields.name = name;

        if (duration !== eventData?.duration) updatedFields.duration = duration;

        if (!isEqual(domains?.slice().sort(), eventData?.authorized_domains?.slice().sort())) {
          updatedFields.authorized_domains = domains;
        }

        if (!isEqual(emails?.slice().sort(), eventData?.invitees?.slice().sort())) {
          updatedFields.invitees = emails;
        }

        if (startTime !== eventData?.start_time) {
          updatedFields.start_time = futureEvent ? startTime : null;
        }

        if (eventKeyUrl !== eventData?.event_url_key) {
          updatedFields.event_url_key = eventKeyUrl;
        }

        if (
          !isEqual(
            selectedOrganizationIds?.slice().sort(),
            eventData?.organization_ids?.slice().sort()
          )
        ) {
          updatedFields.organization_ids = selectedOrganizationIds;
          updatedFields.type = selectedOrganizationIds.length > 1 ? 'MULTIPLE' : 'SINGLE';
        }

        if (!isEqual(streamIds?.slice().sort(), eventData?.stream_ids?.slice().sort())) {
          updatedFields.stream_ids = streamIds;
        }

        await editEvent({ id, ...updatedFields }).unwrap();
      } else {
        const newEventData: Partial<Event> = {
          name,
          duration,
          authorized_domains: domains,
          organization_ids: selectedOrganizationIds,
          stream_ids: streamIds,
          invitees: emails,
          start_time: futureEvent ? startTime : undefined,
          type: selectedOrganizationIds.length > 1 ? 'MULTIPLE' : 'SINGLE',
          event_url_key: eventKeyUrl
        };

        await createEvent(newEventData).unwrap();
      }

      setIsConfirmModalOpen(true);
      // resetForm();
    } catch (e) {
      handleCatch(e);
    }
  };

  const handleCancel = async () => {
    if (id) {
      populateForm(true);
      resetTable();
    } else {
      resetForm();

      if (currentUser?.role === ROLES.LOCAL_ADMIN) {
        fetchDevicesIfNeeded();
      }
    }
  };

  const handleSortChange = (sort: Sort<StreamingDevice>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);

    // if (!id) resetForm();
  };

  const handleConfirm = () => {
    navigate(ROUTES.EVENTS);
  };

  const handleErrorEvent = () => {
    setIsErrorEventModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsStopEventModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsStopEventModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteEventModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteEventModalOpen(true);
  };

  const onStopEvent = async () => {
    if (id) {
      try {
        setLoading(true);
        await stopEvent({ id }).unwrap();

        setTimeout(() => {
          setLoading(false);
          navigate(ROUTES.EVENTS);
        }, 3000);

        setIsStopEventModalOpen(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const onDeleteEvent = async () => {
    if (id) {
      try {
        setLoading(true);
        await deleteEvent({ id }).unwrap();

        setTimeout(() => {
          setLoading(false);
          navigate(ROUTES.EVENTS);
        }, 3000);

        setIsDeleteEventModalOpen(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="event-form">
      <div className="event-form-title-container">
        <h1 className="event-form-title">{id ? 'Edit' : 'Create'} Event</h1>
        <NavLink to={ROUTES.EVENTS} className="event-form-link">
          Back
        </NavLink>
      </div>
      <div className="event-form-inputs-wrapper">
        <div className="event-form-date-wrapper">
          <label className="event-form-schedule-label">Schedule Event</label>
          <div className="event-form-date-container">
            <div className="event-form-future-event">
              <Checkbox checked={futureEvent} onChange={() => setFutureEvent(!futureEvent)} />
              <span onClick={() => setFutureEvent(!futureEvent)}>Future Event?</span>
            </div>

            <DateTimePicker
              startDate={startTime && futureEvent ? new Date(startTime) : undefined}
              onSelectDate={(date: Date) => setStartTime(date.toISOString())}
              disabled={!futureEvent}
            />
          </div>
        </div>

        <InputField
          type="text"
          className="event-form-input"
          value={name}
          onChange={handleNameChange}
          id="name"
          label="Event Name"
          placeholder="Enter Event Name"
        />

        <Select
          disabled={currentUser?.role !== ROLES.VAR}
          multiple
          label="Organizations"
          value={selectedOrganizationIds}
          onChange={(value) => handleSelectOrganizations(value as string[])}
          id="select-organization"
          options={organizationsOptions || []}
          onClose={fetchDevicesIfNeeded}
          onOpen={handleSelectOpen}
        />

        <Select
          label="Duration"
          value={duration}
          onChange={(value) => handleSelectDurationChange(value as number)}
          id="select-duration"
          options={timeOptions}
        />

        <DomainPillInput label="Domains" domains={domains} onChange={setDomains} />

        <EmailPillInput label="Invitees" emails={emails} onChange={setEmails} />

        <CNCTable<StreamingDevice>
          key={tableKey}
          label="Streaming Devices"
          className="event-form-devices-table"
          data={localDevices}
          loading={isLoadingBulk}
          selectionType="multi"
          columns={devicesColumns}
          scrollX={300}
          onSelect={onSelectDevice}
          scrollY={350}
          showPagination={false}
          initialSelectedIds={streamIds}
          selectAll
          onSort={handleSortChange}
        />

        <InputField
          disabled
          type="text"
          description={
            id && (
              <div>
                <p>
                  If you are reusing this event, click on the refresh key (image of the icon) to
                  provide an updated event key to invitees.
                </p>
                <br></br>
                <p>
                  Otherwise, do not refresh the event key so invitees are directed to the correct
                  URL.{' '}
                </p>
              </div>
            )
          }
          className="event-form-input"
          value={eventKeyUrl}
          id="event-key"
          label="Event Key"
          isCopyable
          additionalIcon={
            <RefreshIcon
              className="event-form-generate-key"
              onClick={generateEventKey}
              tabIndex={0}
              onKeyDown={generateEventKey}
              role="button"
            />
          }
        />
      </div>

      <div className="event-form-buttons-container">
        <Button variant="primary" disabled={!shouldEnableSaveChanges} onClick={handleSubmit}>
          Save Event
        </Button>
        <Button variant="secondary" onClick={handleCancel} disabled={!shouldEnableCancel}>
          Undo
        </Button>
        {id && (
          <>
            <Button
              disabled={eventData?.state === 'finished'}
              variant="delete"
              className="user-form-delete-button"
              onClick={handleOpenModal}
            >
              Stop Event
            </Button>
            <Button
              disabled={eventData?.state === 'active'}
              variant="delete"
              className="user-form-delete-button"
              onClick={handleOpenDeleteModal}
            >
              Delete Event
            </Button>
          </>
        )}
      </div>

      {isFetchBaseQueryError(errorCreateEvent) && (
        <ConfirmModal
          isOpen={isErrorEventModalOpen}
          onConfirm={handleErrorEvent}
          message={
            errorCreateEvent.data.message === 'Event overlapping'
              ? EVENT_OVERLAPPING_ERROR_MESSAGE
              : errorCreateEvent.data.message
          }
          confirmText="Ok"
          variant="primary"
        />
      )}

      {isFetchBaseQueryError(errorUpdateEvent) && (
        <ConfirmModal
          isOpen={isErrorEventModalOpen}
          onConfirm={handleErrorEvent}
          message={
            errorUpdateEvent.data.message === 'Event overlapping'
              ? EVENT_OVERLAPPING_ERROR_MESSAGE
              : errorUpdateEvent.data.message
          }
          confirmText="Ok"
          variant="primary"
        />
      )}

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        message={`Your event has been ${id ? 'modified' : 'created'}`}
        cancelText="Stay on this page"
        confirmText="Go to all events"
        variant="primary"
      />

      <ConfirmModal
        isOpen={isStopEventModalOpen}
        onClose={handleCloseModal}
        onConfirm={onStopEvent}
        message="Are you sure you want to stop this event?"
      />

      <ConfirmModal
        isOpen={isDeleteEventModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={onDeleteEvent}
        message="Are you sure you want to delete this event?"
      />
    </div>
  );
};

export default EventForm;
